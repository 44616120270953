import { UseSpringProps } from '@react-spring/core/dist/declarations/src/hooks/useSpring'

export const getInputLabelAnimationConfig = (
  isFocused: boolean,
  duration = 200,
): UseSpringProps => {
  const config = { duration }
  if (isFocused) {
    return {
      ...config,
      from: { transform: 'translateY(0px)' },
      to: { transform: 'translateY(-6px)' },
      config: { tension: 500 },
    }
  }

  return {
    ...config,
    to: { transform: 'translateY(0px)' },
    config: { tension: 500 },
  }
}
