import React, { useEffect, useMemo } from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { ContentWrapper, InfoWrapper, Loading, ScreenWrapper } from '../../../lib/styles'
import Header from '../../../lib/components/Header'
import BackButtonArrow from '../../../lib/components/BackButton'
import { usePlanConditionsState } from '../../Plans/store'
import { formatPlanConditions } from '../../Prospects/utils'
import {
  Organization,
  OrganizationStatus,
  useGetOrganizationQuery,
} from '../../../GeneratedGraphQLTypes'
import { useOrganizationDetailsStore } from './store'
import { DetailsScreenWrapper, DetailsWrapper } from '../styles'
import { HubGhostModeButton } from './HubGhostModeButton'
import { CardOrderStatusSwitcher } from './CardOrderStatusSwitcher'
import { BezalaActivation } from './BezalaActivation/BezalaActivation'
import { SalesRepresentative } from './SalesRepresentative'
import { CreditApplicationInfo } from './CreditApplicationInfo'
import { OrganizationPlans } from './OrganizationPlans'
import { OnboardingFee } from './OnboardingFee'
import { ActiveUsers } from './ActiveUsers'
import { AdditionalTerms } from './AdditionalTerms'
import { Comments } from './Comments'
import CardStatusPrompt from '../CardStatusPrompt'
import { BezalaActivationModal } from './BezalaActivation/BezalaActivationModal'
import OrganizationM2Input from './OrganizationM2Input'
import OrganizationAcubizInput from './OrganizationAcubizInput'
import { OrganizationNoteWithSave } from '../Note'
import { CountryKey } from '@zevoy/common/src/types/Generic'
import { omit } from 'lodash'
import { DefaultNote } from '../Note/OrganizationNote'
import BankAccount from '../BankAccount'
import { ATMWithdrawalLimits } from './ATMWithdrawalLimits'
import { FXFee } from './FXFee'
import { Cashback } from './Cashback'
import { PaymentTerms } from './PaymentTerms'
import { OrganizationStatusSelect } from './OrganizationStatusSelect'
import { EnfuceAccounts } from './EnfuceAccounts'
import { PersonalLiabilityActivation } from './PersonalLiabilityActivation'

export const OrganizationDetails = () => {
  const { setOrganization } = useOrganizationDetailsStore()
  const { setPlanConditions, setError } = usePlanConditionsState()
  const { setSalesRep } = useOrganizationDetailsStore()

  const navigate = useNavigate()
  const { id } = useParams()
  const { data, loading } = useGetOrganizationQuery({
    variables: {
      id,
    },
  })

  useEffect(() => {
    const organization = data?.organization as Organization
    if (!organization) {
      return
    }

    setOrganization(organization)
    setError('')
    setPlanConditions(formatPlanConditions(organization.plan?.conditions ?? []))
    setSalesRep(organization.salesRep?.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const organization = data?.organization as Organization
  const existingNote = useMemo(() => {
    return omit(
      { ...organization?.note, channelId: organization?.note?.channel?.id ?? 1 } ?? DefaultNote,
      ['__typename', 'channel'],
    )
  }, [organization])

  if (loading || !organization) {
    return (
      <ScreenWrapper>
        <Sidebar />
        <ContentWrapper>
          <Loading />
        </ContentWrapper>
      </ScreenWrapper>
    )
  }

  return (
    <ScreenWrapper>
      <Sidebar />
      <ContentWrapper>
        <Header
          title={organization?.name}
          backButton={
            <BackButtonArrow
              onClick={() => {
                navigate(-1)
              }}
            />
          }
          customLabel={'ORGANIZATIONS /'}
        />

        <DetailsScreenWrapper>
          <DetailsWrapper>
            <InfoWrapper>
              <HubGhostModeButton />
              {organization.status !== OrganizationStatus.Archived && (
                <CardOrderStatusSwitcher status={organization.status} />
              )}
              <BezalaActivation organization={organization} />
              <PersonalLiabilityActivation
                isPersonalLiabilityActive={
                  !!organization.featureSettings?.personalLiabilityCardsEnabled
                }
                organizationId={organization.id}
              />
              <OrganizationStatusSelect organization={organization} />
              <EnfuceAccounts organization={organization} />
              <SalesRepresentative />
              <CreditApplicationInfo />
              <ATMWithdrawalLimits />
              <OrganizationPlans organization={organization} />
              <Cashback />
              <FXFee />
              <PaymentTerms />
              <OnboardingFee />
              <AdditionalTerms />
              <Comments />
              <OrganizationM2Input />
              <OrganizationAcubizInput />
              <ActiveUsers />
              <OrganizationNoteWithSave
                organizationId={organization.id}
                note={existingNote}
                country={organization.country as CountryKey}
              />
              {![OrganizationStatus.Archived, OrganizationStatus.Prospect].includes(
                organization.status,
              ) && <BankAccount />}
            </InfoWrapper>
          </DetailsWrapper>
        </DetailsScreenWrapper>
      </ContentWrapper>
      <Routes>
        <Route path="prompt" element={<CardStatusPrompt />} />
        <Route path="activate-bezala" element={<BezalaActivationModal />} />
      </Routes>
    </ScreenWrapper>
  )
}
