import { MutableRefObject, useEffect, useLayoutEffect, useRef, useState } from 'react'
import useResizeObserver from '@react-hook/resize-observer'

export function useOutsideClick(
  ref: MutableRefObject<any>,
  cb: any,
  event: keyof DocumentEventMap = 'mousedown',
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      // Prevent outside click from registering if user is selecting text
      if (window.getSelection !== undefined && window.getSelection()?.toString() !== '') return
      const containsElement =
        event.composedPath() === undefined || // If composedPath is undefined then the element does not exist anymore
        event.composedPath().includes(ref.current) ||
        (ref.current && ref.current.contains(event.target))
      if (ref?.current && !containsElement) {
        cb()
      }
    }

    // give some time for ref to be mounted before the event listener is added to avoid side effects.
    const timer = setTimeout(() => {
      document.addEventListener(event, handleClickOutside)
    }, 10)
    return () => {
      clearTimeout(timer)
      document.removeEventListener(event, handleClickOutside)
    }
  }, [ref, cb, event])
}

export const useWindowResize = (cb: any) => {
  useEffect(() => {
    window.addEventListener('resize', cb)
    return () => {
      window.removeEventListener('resize', cb)
    }
  }, [cb])
}

enum Dimensions {
  height = 'height',
  width = 'width',
}
const useDimension = (ref: MutableRefObject<HTMLElement | null>, dimension: 'height' | 'width') => {
  const [size, setSize] = useState<number | null>(null)
  const prevRefCurrent = useRef<HTMLElement | null>(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (prevRefCurrent.current !== ref.current) {
      prevRefCurrent.current = ref.current
      if (!ref.current) {
        return
      }

      switch (dimension) {
        case Dimensions.height:
          setSize(ref.current.offsetHeight)
          break
        case Dimensions.width:
          setSize(ref.current.offsetWidth)
          break
        default:
          break
      }
    }
  })

  useResizeObserver(ref, (entry) => {
    const totalSize =
      entry?.borderBoxSize?.reduce(
        (acc, resizeEntity) =>
          acc +
            (dimension === Dimensions.height ? resizeEntity.blockSize : resizeEntity.inlineSize) ??
          0,
        0,
      ) ?? 0
    setSize(totalSize ?? 0)
  })

  return size ?? 0
}

export const useHeight = (ref: MutableRefObject<HTMLElement | null>) =>
  useDimension(ref, Dimensions.height)
export const useWidth = (ref: MutableRefObject<HTMLElement | null>) =>
  useDimension(ref, Dimensions.width)

export const useScrollHeight = (ref: MutableRefObject<HTMLElement | null>) => {
  const [size, setSize] = useState<number | null>(null)

  useLayoutEffect(() => {
    setTimeout(() => {
      if (!ref?.current) {
        return
      }
      setSize(ref.current.scrollHeight)
    }, 200)
  }, [ref])

  useResizeObserver(ref, (entry) => {
    return setTimeout(() => {
      setSize(entry.target.scrollHeight ?? 0)
    }, 200)
  })
  return size ?? 0
}

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>()

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}
