import React, { useEffect, useState } from 'react'
import { DetailsHeader, DetailsUnderline, ModalInputWrapper } from '../styles'
import tw from 'twin.macro'
import { useOrganizationDetailsStore } from './store'
import { SelectComponent } from '@zevoy/common/src/components/SelectComponent'
import { Option } from '@zevoy/common/src/types/Generic'
import { InfoWrapper } from '../../Prospects/styles'
import { useUpdatePaymentTermsMutation } from '../../../GeneratedGraphQLTypes'
import { useParams } from 'react-router-dom'
import { SaveButton } from '../../../lib/styles'
import { toast } from 'react-toastify'

const Wrapper = tw(InfoWrapper)`p-0 pt-8`

const PAYMENT_TERMS_14 = 14
const PAYMENT_TERMS_30 = 30
const DEFUALT_PAYMENT_TERMS = PAYMENT_TERMS_14

export const PaymentTerms = () => {
  const { organization } = useOrganizationDetailsStore()
  const [paymentTerms, setPaymentTerms] = useState<number>(
    organization?.invoiceSetting?.paymentTerms ?? DEFUALT_PAYMENT_TERMS,
  )

  useEffect(() => {
    setPaymentTerms(organization?.invoiceSetting?.paymentTerms ?? DEFUALT_PAYMENT_TERMS)
  }, [organization?.invoiceSetting?.paymentTerms])

  const { id } = useParams()

  const [updatePaymentTerms] = useUpdatePaymentTermsMutation({
    variables: {
      organizationID: id,
      paymentTerms,
    },
  })

  const handleSave = async () => {
    try {
      await updatePaymentTerms()
      toast.success('Payment terms saved!')
    } catch (e) {
      const err: Error = e as Error
      console.error(err?.message ?? e)
      toast.error('Failed to save!')
    }
  }
  const paymentTermsOptions: Option[] = [
    { label: '14', value: PAYMENT_TERMS_14 },
    { label: '30', value: PAYMENT_TERMS_30 },
  ]

  return (
    <Wrapper>
      <DetailsHeader>Payment Terms</DetailsHeader>
      <DetailsUnderline />
      <ModalInputWrapper>
        <SelectComponent
          options={paymentTermsOptions}
          value={paymentTermsOptions.find((option: Option) => option.value === paymentTerms)}
          onChange={(newValue) => {
            setPaymentTerms(newValue?.value as number)
          }}
        />
      </ModalInputWrapper>
      <SaveButton onClick={handleSave}>Save Payment Terms</SaveButton>
    </Wrapper>
  )
}
