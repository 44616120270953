import React from 'react'
import tw, { css, styled, theme } from 'twin.macro'
import { Alignments } from '../../constants'
import { FlattenSimpleInterpolation } from 'styled-components'

type StackProps = {
  children: React.ReactNode
  align?: Alignments
  styled?: FlattenSimpleInterpolation
  gap?: number
  width?: string
}

type StackWrapperProps = {
  align?: Alignments
  styled?: FlattenSimpleInterpolation
  gap?: number
  width?: string
}

const StackWrapper = styled.div<StackWrapperProps>`
  ${tw`flex flex-col gap-2`}
  ${({ align }) =>
    align === Alignments.Left
      ? tw`items-start`
      : align === Alignments.Center
      ? tw`items-center`
      : align === Alignments.Right
      ? tw`items-end`
      : ''}
  ${({ gap }) =>
    (gap || gap === 0) &&
    css`
    gap: calc(${theme`spacing.1`} * ${gap});
  }
  `}
  ${({ width }) =>
    width &&
    css`
      width: ${width};
    `}
  ${({ styled }) =>
    styled &&
    css`
      ${styled}
    `}
`

export const Stack = ({ children, align, styled, gap, width }: StackProps) => {
  return (
    <StackWrapper align={align} styled={styled} gap={gap} width={width}>
      {children}
    </StackWrapper>
  )
}
