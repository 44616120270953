import React, { useCallback, useRef, useState } from 'react'
import { ContentWrapper } from '../../../lib/styles'
import Header from '../../../lib/components/Header'
import BackButtonArrow from '../../../lib/components/BackButton'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ArrayInput,
  DateTimeInput,
  Edit,
  SaveButton,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useGetList,
  useGetOne,
  useNotify,
  useUpdate,
} from 'react-admin'
import { sortBy, isEqual } from 'lodash'
import {
  BenefitCategory,
  BenefitMerchant,
  BenefitMerchantLocation,
  OpeningHour,
} from '../../../GeneratedGraphQLTypes'
import { BenefitCategoryType } from 'admin-client/src/Benefits/BenefitsServicesOffered/types'
import { BenefitOptionSections } from '../BenefitApplication/BenefitOptionSections'
import { agreementChannelOptions, BenefitTypes, DayMapping } from '../BenefitApplication/constants'
import { LabelComponent } from '../BenefitApplication/BenefitApplicationList'
import tw from 'twin.macro'
import { BusinessIdValidationRules } from '@zevoy/common/src/types/Generic'
import { Stack } from '@zevoy/common/src/components/Spacing'
import MerchantLocations from './MerchantLocations'
import { generateUniqueNumberId } from '../BenefitApplication/BenefitApplicationEdit'
import {
  formatTimeOpeningHour,
  sortOpeningHours,
  validateAgreementChannel,
} from '../BenefitApplication/utils'

const EmptyDataWrapper = tw.div`py-5`

type ValidateFormErrorTypes = {
  businessID?: string
}

export const MerchantEdit = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const notify = useNotify()
  const [update] = useUpdate()

  const emptyBenefitLocation = () => ({
    name: '',
    location: '',
    id: generateUniqueNumberId(),
    googleID: '',
    geometry: undefined,
    address: '',
    merchantID: id,
    openingHours: undefined,
  })

  const { data: benefitCategoryData } = useGetList<BenefitCategory>('benefitCategory', {})
  const { data: merchantData } = useGetOne<BenefitMerchant>(
    'merchant',
    {
      id,
    },
    {
      onSuccess: (data) => {
        if (data.tags) {
          const lunchTags = data.tags.filter((tag) => tag.categoryID === 1)
          const cultureTags = data.tags.filter((tag) => tag.categoryID === 2)
          const sportTags = data.tags.filter((tag) => tag.categoryID === 3)

          setLunchBenefitSelectedOptions(lunchTags)
          setCultureBenefitSelectOptions(cultureTags)
          setSportBenefitSelectOptions(sportTags)
        }

        if (data.locations) {
          setBenefitLocations(data?.locations)
        }
      },
    },
  )

  const [benefitLocations, setBenefitLocations] = useState<BenefitMerchantLocation[]>([])
  const [lunchBenefitSelectedOptions, setLunchBenefitSelectedOptions] = useState<
    BenefitCategoryType[]
  >([])
  const [cultureBenefitSelectOptions, setCultureBenefitSelectOptions] = useState<
    BenefitCategoryType[]
  >([])
  const [sportBenefitSelectOptions, setSportBenefitSelectOptions] = useState<BenefitCategoryType[]>(
    [],
  )

  const getSortedBenefitCategories = (langID: BenefitTypes) => {
    const benefitTypeTags =
      benefitCategoryData?.find((benefitType) => benefitType.langID === langID)?.tags || []

    return benefitTypeTags.slice().sort((a, b) => {
      if (a.title === 'Other') return 1 // Move "Other" towards the end
      if (b.title === 'Other') return -1 // Keep "Other" at the end if it's `b`
      return 0 // Keep original order if neither is "Other"
    })
  }

  const lunchBenefitCategories = getSortedBenefitCategories(BenefitTypes.LUNCH)
  const cultureBenefitCategories = getSortedBenefitCategories(BenefitTypes.CULTURE)
  const sportBenefitCategories = getSortedBenefitCategories(BenefitTypes.SPORT)

  const handleOptionClick = (option: BenefitCategoryType, benefitType: BenefitTypes) => {
    const updateSelectedOptions = (selectedOptions: BenefitCategoryType[]) => {
      const isSelected = selectedOptions.some((selectedOption) => selectedOption.id === option.id)
      if (isSelected) {
        return selectedOptions.filter((selectedOption) => selectedOption.id !== option.id)
      } else {
        return [...selectedOptions, option]
      }
    }

    if (benefitType === BenefitTypes.LUNCH) {
      setLunchBenefitSelectedOptions((prevOptions) => updateSelectedOptions(prevOptions))
    } else if (benefitType === BenefitTypes.CULTURE) {
      setCultureBenefitSelectOptions((prevOptions) => updateSelectedOptions(prevOptions))
    } else if (benefitType === BenefitTypes.SPORT) {
      setSportBenefitSelectOptions((prevOptions) => updateSelectedOptions(prevOptions))
    }
  }

  const formattedSelectedLunchOptions = lunchBenefitSelectedOptions.map((option) => option.id)
  const formattedSelectedCultureOptions = cultureBenefitSelectOptions.map((option) => option.id)
  const formattedSelectedSportOptions = sportBenefitSelectOptions.map((option) => option.id)

  const benefitTagList = [
    ...formattedSelectedLunchOptions,
    ...formattedSelectedCultureOptions,
    ...formattedSelectedSportOptions,
  ]

  const serverBenefitTagList = merchantData?.tags?.map((tag) => tag.id)
  const isTagsModified = !isEqual(sortBy(benefitTagList), sortBy(serverBenefitTagList))
  const serverMerchantLocationData = merchantData?.locations
  const isLocationModified = !isEqual(sortBy(serverMerchantLocationData), sortBy(benefitLocations))

  const updateMerchant = (data: any) => {
    const updatedBenefitLocations = benefitLocations.map(({ id, merchantID, ...rest }) => rest)
    update(
      'merchant',
      {
        id: data.id,
        data: {
          merchant: {
            id: data.id,
            name: data.name,
            businessID: data.businessID,
            contactName: data.contacts[0].name,
            contactEmail: data.contacts[0].email,
            contactPhoneNumber: data.contacts[0].phoneNumber,
            locations: updatedBenefitLocations,
            externalMerchantIDs: data.externalMerchantIDs,
            tags: benefitTagList,
            agreementChannel: data.agreementChannel,
          },
        },
        previousData: {},
      },
      {
        onSuccess: () => {
          notify('Update successfully', { type: 'success' })
        },
        onError: (error: any) => {
          notify(`Update failed: ${error?.message ?? 'Unknown error'}`, { type: 'error' })
        },
      },
    )
  }

  const validateForm = (values: any) => {
    const errors: ValidateFormErrorTypes = {}
    const businessIdValidationRule = BusinessIdValidationRules.find(
      (item) => item.countryCode === merchantData?.country,
    )

    if (
      values.businessID &&
      businessIdValidationRule?.regex &&
      !values.businessID.match(businessIdValidationRule.regex)
    ) {
      errors.businessID = 'Wrong format for businessID'
    }
    return errors
  }

  const autocompleteRefs = useRef<{ [key: string]: google.maps.places.Autocomplete | null }>({})

  const onPlaceChanged = (locationId: number) => {
    if (autocompleteRefs.current[locationId]) {
      const place = autocompleteRefs.current[locationId]?.getPlace()

      if (place) {
        const geometry = place.geometry?.location
          ? {
              longitude: place.geometry.location.lng(),
              latitude: place.geometry.location.lat(),
            }
          : undefined

        let openingHours: OpeningHour[] | undefined = place.opening_hours?.periods
          ?.map((period) => {
            return {
              closingTime:
                period.close && formatTimeOpeningHour(period.close.hours, period.close.minutes),
              dayOfWeek: DayMapping[period.open.day],
              openingTime:
                period.close && formatTimeOpeningHour(period.open.hours, period.open.minutes),
              isOpen24h: !period.close,
            }
          })
          .filter((hour) => hour !== undefined) as OpeningHour[]

        // Special case check: If only one entry, closingTime is undefined, and dayOfWeek is Sunday
        if (
          openingHours &&
          openingHours.length === 1 &&
          openingHours[0].closingTime === undefined &&
          openingHours[0].dayOfWeek === DayMapping[0] // DayMapping[0] is sunday
        ) {
          // Set openingTime and closingTime to undefined for all days
          openingHours = Object.values(DayMapping).map((day) => ({
            dayOfWeek: day,
            openingTime: undefined,
            closingTime: undefined,
            isOpen24h: true,
          })) as OpeningHour[]
        }

        if (openingHours) {
          sortOpeningHours(openingHours)
        }

        handleLocationChange(locationId, {
          name: place.name ?? '',
          location: place.url ?? '',
          googleID: place.place_id ?? '',
          address: place.formatted_address ?? '',
          geometry,
          openingHours,
        })
      }
    }
  }

  const handleAddAnotherLocation = () => {
    setBenefitLocations([...benefitLocations, emptyBenefitLocation()])
  }

  const handleRemoveLocation = (id: number) => {
    setBenefitLocations((currentLocations) => {
      return currentLocations.filter((location) => location.id !== id)
    })
  }

  const handleLocationChange = (id: number, fields: Partial<BenefitMerchantLocation>) => {
    setBenefitLocations((currentLocations) =>
      currentLocations.map((location) =>
        location.id === id ? { ...location, ...fields } : location,
      ),
    )
  }

  const onLoad = useCallback((id: number, autocomplete) => {
    autocompleteRefs.current[id] = autocomplete
  }, [])

  return (
    <ContentWrapper>
      <Header
        title={'Edit merchant'}
        backButton={
          <BackButtonArrow
            onClick={() => {
              navigate('/admin/merchant')
            }}
          />
        }
      />
      <Edit>
        <SimpleForm toolbar={false} onSubmit={updateMerchant} validate={validateForm}>
          <Stack width={'500px'}>
            <SelectInput
              source="agreementChannel"
              choices={agreementChannelOptions}
              validate={validateAgreementChannel}
            />
            <TextInput source="name" label="Official company name" fullWidth />
            <TextInput source="businessID" label="Business ID" fullWidth required />
            <ArrayInput source="contacts" label="">
              <SimpleFormIterator fullWidth disableAdd disableReordering disableRemove>
                <TextInput source="name" label="Contact name" fullWidth />
                <TextInput source="email" label="Contact email" fullWidth type={'email'} />
                <TextInput source="phoneNumber" label="Contact phone number" fullWidth />
              </SimpleFormIterator>
            </ArrayInput>

            <MerchantLocations
              benefitLocations={benefitLocations}
              handleRemoveLocation={handleRemoveLocation}
              handleAddAnotherLocation={handleAddAnotherLocation}
              onLoad={onLoad}
              onPlaceChanged={onPlaceChanged}
              handleLocationChange={handleLocationChange}
            />

            <ArrayInput
              source="externalMerchantIDs"
              label={<LabelComponent labelName={'External merchant IDs'} />}
              fullWidth
            >
              <SimpleFormIterator fullWidth>
                <TextInput
                  source="acquireReferenceNumber"
                  label="Acquire reference number"
                  fullWidth
                />
                <TextInput source="externalMerchantID" label="External merchant ID" fullWidth />
              </SimpleFormIterator>
            </ArrayInput>
          </Stack>

          <BenefitOptionSections
            benefitsList={lunchBenefitCategories}
            selectedOptions={lunchBenefitSelectedOptions}
            handleOptionClick={(option) => handleOptionClick(option, BenefitTypes.LUNCH)}
            benefitTitle="Lunch tags"
          />
          <BenefitOptionSections
            benefitsList={cultureBenefitCategories}
            selectedOptions={cultureBenefitSelectOptions}
            handleOptionClick={(option) => handleOptionClick(option, BenefitTypes.CULTURE)}
            benefitTitle="Culture tags"
          />
          <BenefitOptionSections
            benefitsList={sportBenefitCategories}
            selectedOptions={sportBenefitSelectOptions}
            handleOptionClick={(option) => handleOptionClick(option, BenefitTypes.SPORT)}
            benefitTitle="Sport tags"
          />

          <SaveButton alwaysEnable={isTagsModified || isLocationModified} />

          <Stack width={'500px'}>
            {merchantData?.signatories ? (
              <ArrayInput
                source="signatories"
                label={<LabelComponent labelName={'Signatories'} />}
                disabled
                fullWidth
              >
                <SimpleFormIterator fullWidth>
                  <TextInput source="signedByUser.fullName" label="Signed by" fullWidth />
                  <DateTimeInput source="signTime" label="Sign time" fullWidth disabled />
                </SimpleFormIterator>
              </ArrayInput>
            ) : (
              <EmptyDataWrapper>
                <LabelComponent labelName={'Signatories: not set'} />
              </EmptyDataWrapper>
            )}
            <DateTimeInput source="createTime" label="Merchant create time" fullWidth disabled />
            <TextInput label="Approved by" fullWidth disabled source="approvedByUser.fullName" />
          </Stack>
        </SimpleForm>
      </Edit>
    </ContentWrapper>
  )
}
