import { StylesConfig, CSSObjectWithLabel, GroupBase } from 'react-select'
import tw, { theme, styled, css } from 'twin.macro'
import { ReactComponent as ChevronDown } from '@zevoy/common/assets/icons/chevron_down.svg'
import { ReactComponent as Close } from '@zevoy/common/assets/icons/close.svg'

export const SelectOptionCustomStyles = {
  background: theme('colors.zevoyWhite'),
  color: theme('colors.zevoyBlueBlack'),
  cursor: 'pointer',
  fontSize: theme('fontSize.sm'),
  paddingTop: '15px',
  paddingBottom: '15px',
  paddingLeft: '20px',
  paddingRight: '20px',
  borderBottom: `1px solid ${theme('colors.zevoyGray3')}`,
  fontFamily: theme('fontFamily.slussenLight'),
  '&:hover': {
    background: theme('colors.zevoyBlue.50'),
  },
  '&:active': {
    background: theme('colors.zevoyBlue.50'),
  },
  '&:last-child': {
    borderBottom: 'none',
  },
  '&:disabled, &[aria-disabled="true"]': {
    cursor: 'not-allowed',
    background: theme('colors.zevoyGray4'),
  },
}

export const getSelectCustomStyles = <
  OptionType,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType>,
>(
  width: string = '100%',
  menuPortalTarget: HTMLElement | null | undefined = null,
  isDisabled?: boolean,
): StylesConfig<OptionType, IsMulti, GroupType> => ({
  control: (styles: CSSObjectWithLabel, { isMulti, menuIsOpen }) => ({
    ...styles,
    border: 'none',
    minHeight: '0px',
    borderRadius: '0px',
    boxShadow: 'none',
    cursor: 'pointer',
    '&:hover': {
      border: 'none',
    },
    height: isMulti && !menuIsOpen ? '0px' : 'inherit',
  }),
  placeholder: (styles: CSSObjectWithLabel, { isMulti }) => ({
    ...styles,
    display: isMulti ? 'none' : 'inherit', // We are using custom placeholder for multi, so the original one should nbe hide
  }),
  valueContainer: (styles: CSSObjectWithLabel) => ({
    ...styles,
    paddingLeft: '0px',
    paddingRight: '0px',
  }),

  indicatorsContainer: (styles: CSSObjectWithLabel) => ({
    ...styles,
    display: 'none',
  }),

  input: (styles: CSSObjectWithLabel) => ({
    ...styles,
    color: theme('colors.zevoyBlueBlack'),
  }),

  // TODO
  multiValue: (styles: CSSObjectWithLabel) => ({
    ...styles,
  }),

  // TODO
  multiValueLabel: (styles: CSSObjectWithLabel) => ({
    ...styles,
  }),

  // TODO
  multiValueRemove: (styles: CSSObjectWithLabel) => ({
    ...styles,
  }),
  option: (styles: CSSObjectWithLabel, { isSelected, isFocused }) => ({
    ...styles,
    ...SelectOptionCustomStyles,
  }),
  menu: (styles: CSSObjectWithLabel) => ({
    ...styles,
    boxShadow: 'none',
    fontFamily: theme('fontFamily.slussenLight'),
    marginBottom: '2px',
    marginLeft: menuPortalTarget ? '-21px' : '0px',
    marginTop: menuPortalTarget ? '12px' : '5px',
    border: `1px solid ${theme('colors.zevoyGray3')}`,
    borderRadius: theme('borderRadius.md'),
    zIndex: 1001,
    width,
  }),
  menuList: (styles: CSSObjectWithLabel) => ({
    ...styles,
    padding: '0px',
    borderRadius: theme('borderRadius.md'),
  }),
  menuPortal: (styles: CSSObjectWithLabel) => ({
    ...styles,
    zIndex: 9999,
  }),
  singleValue: (styles: CSSObjectWithLabel) => ({
    ...styles,
    color: isDisabled ? theme('colors.zevoyGray3') : theme('colors.zevoyBlueBlack'),
  }),
})

export const ClearSelection = styled(Close).attrs({
  className: 'clear-field',
})`
  ${tw`flex-initial fill-zevoyGray3 -mr-[5px]`}
  ${css`
    &:hover {
      ${css`
        fill: ${theme('colors.zevoyGray2')};
      `}
    }
  `}
`

export const Chevron = tw(ChevronDown)`flex-initial fill-zevoyGray3 -mr-[5px]`

type SelectedRowProps = {
  isDisabled?: boolean
  isOpen?: boolean
  width?: string
}
export const SelectedRow = styled.div<SelectedRowProps>`
  ${tw`flex justify-between py-[7px] border-b border-zevoyGray4 text-zevoyBlueBlack items-center pl-[5px]`}

  ${({ isDisabled }) =>
    isDisabled ? tw`cursor-default bg-zevoyGray4` : tw`hover:bg-zevoyBlue-50 hover:cursor-pointer`}
`

export type WrapperProps = {
  width?: string
}
export const Wrapper = styled.div<WrapperProps>`
  ${({ width }) => width && `width: ${width}`}
`

export const SelectPlaceholder = tw.div`py-[7px] text-sm text-zevoyGray3 leading-5`

export const SelectedRowWrapper = tw.div`mt-3`
