import { gql } from '@apollo/client'
import { DataProvider, UpdateParams } from 'react-admin'
import { omit, pick, upperFirst } from 'lodash'
import { authClient } from '../../lib/apolloClient'

type Action = {
  fields?: string
  endpoint?: string
  inputType?: string
  resource?: string
  paging?: boolean
  filtering?: boolean
  idParameter?: string
  idType?: string
}

type ResourceField = {
  fields: string
  getList?: Action
  getOne?: Action
  create?: Action
  delete?: Action
  deleteMany?: Action
  update?: Action
  submit?: Action
  approve?: Action
  reject?: Action
}

type Resources = {
  [key: string]: ResourceField
}

const client = authClient({ resultCaching: false })

const resources: Resources = {
  prospect: {
    fields: `id name status country createTime salesRep {id fullName} KYCSessions{token}`,
    getList: { paging: true, resource: 'organization', filtering: true },
    create: {
      endpoint: 'createProspectNg',
      resource: 'organization',
    },
    delete: {
      resource: 'organization',
    },
    getOne: {
      fields: `id name businessID country salesRep{id   fullName} onboardingFee 
        plan { organization { id } type startTime additionalTerms comments 
        conditions {
          startDate
          type
          amountPerUser
          amountPerBusinessCardUser
          amountPerClaimUser
          amountPerSubscription
          minimumBillingFee
          fixedFee
          freeClaimUsers
          freeBusinessCardUsers
          freeSubscriptionCards
          freeClaimForBusinessCardUser
        } } 
        employeeRange { range } status KYCSessions { token createdAt}`,
      resource: 'organization',
    },
    update: {
      fields: 'id name salesRepID Country businessID',
      resource: 'organization',
    },
  },
  salesRep: {
    fields: `id fullName country`,
    getList: {
      endpoint: 'salesRep',
    },
    getOne: {
      resource: 'user',
    },
  },
  tag: {
    fields: `id name type status`,
    getList: {
      paging: false,
      filtering: true,
    },
  },
  tagValue: {
    fields: `id name value status tag {id}`,
    getList: {
      paging: true,
      filtering: true,
    },
  },
  organization: {
    fields: `id name status country createTime salesRep {id fullName} KYCSessions{token, signedAt} plan{type} fundingHistory{type}`,
    getList: {
      paging: true,
      filtering: true,
      endpoint: 'organizations',
    },
  },
  card: {
    fields: `id, cardName, cardType, lastFour, status, cardHolderName, segment`,
    getList: {
      paging: true,
      filtering: true,
      endpoint: 'cards',
    },
    getOne: {
      endpoint: 'card',
      idParameter: 'cardID',
      fields: `
        id, cardName, cardType, firstFour, lastFour, status, cardHolderName, segment, expirationDate { year, month},
        regionalBlockStatuses { region, status }, totalTransactionCount, description, 
        usageLimits { code, values { code, singleAmount, count, sumAmount} }
        account { id } organization { name }
        monthlyUsageLimit { code, values { code, singleAmount, count, sumAmount} }
        cardLimits { monthly { all { used { amount, currency }, total { amount, currency }, single { amount, currency }, endTime } } }
      `,
    },
  },
  cardRequest: {
    fields: `id, organization { id, name } status, user { id, fullName, address {address1}} createTime`,
    getList: {
      paging: true,
      filtering: true,
      endpoint: 'cardRequests',
    },
    delete: {
      fields: `id`,
      resource: 'card',
      endpoint: 'cancelCardRequest',
    },
    submit: {
      fields: `id`,
      resource: 'card',
      endpoint: 'submitCardRequest',
    },
  },
  user: {
    fields: `id fullName scopedRoles`,
    getList: {
      paging: true,
      filtering: true,
    },
    getOne: {
      resource: 'user',
      fields: `id fullName scopedRoles`,
    },
  },
  scopedRoles: {
    fields: ``,
    update: {
      endpoint: `setScopedRoles`,
      inputType: `SetScopedRolesInput!`,
      resource: 'user',
    },
  },
  creditApplication: {
    fields: `id type status createTime requested{ amount currency } approved{ amount currency } user{id fullName} organization{ name salesRep { fullName }}`,
    getList: {
      paging: true,
      filtering: true,
      endpoint: 'creditApplications',
    },
  },
  merchant: {
    fields:
      'id agreementChannel name createTime businessID country externalMerchantIDs { externalMerchantID acquireReferenceNumber } contacts { name email phoneNumber  } locations { id name location googleID address geometry { latitude longitude } openingHours { isOpen24h openingTime closingTime dayOfWeek } } tags { id categoryID title } signatories { signedByUser {fullName} signTime } approvedByUser {fullName}',
    getList: {
      paging: true,
      filtering: true,
      endpoint: 'merchants',
    },
    getOne: {
      endpoint: 'merchantById',
      resource: 'merchantById',
    },
    update: {
      endpoint: `updateMerchant`,
      inputType: `MerchantInput`,
    },
  },
  benefitApplication: {
    fields: `id agreementChannel createdByUser {fullName} name businessID contactName contactEmail contactPhoneNumber country locations { name location address googleID geometry { latitude longitude } openingHours { isOpen24h openingTime closingTime dayOfWeek } } status benefits { category tags {id title }} invites { id email fullName isSignatory createdByUser {fullName} createTime expiresOn} externalMerchantIDs { acquireReferenceNumber merchantID } signatories { signTime signedByUser { fullName } }`,
    getList: {
      paging: true,
      filtering: true,
      endpoint: 'merchantApplications',
      resource: 'merchantApplication',
      inputType: 'MerchantApplicationsFilter',
    },
    getOne: {
      endpoint: 'merchantApplication',
      resource: 'merchantApplication',
    },
    update: {
      endpoint: `saveMerchantApplication`,
      inputType: `ApplicationInput`,
    },
    approve: {
      endpoint: 'approveMerchantApplication',
      inputType: 'MerchantApplicationIDInput!',
      fields: 'id createdBy name status',
    },
    reject: {
      endpoint: 'rejectMerchantApplication',
      inputType: 'MerchantApplicationIDInput!',
      fields: 'id createdBy name status',
    },
  },
  benefitCategory: {
    fields: 'id langID tags { id categoryID title }',
    getList: {
      paging: false,
      filtering: false,
      endpoint: 'benefitCategories',
      resource: 'benefitCategories',
    },
  },
  transaction: {
    fields: 'id text',
    getOne: {
      endpoint: 'transaction',
      resource: 'transaction',
    },
  },
  availableTransactionMerchantID: {
    fields: 'id merchantName merchantID acquireReferenceNumber',
    getList: {
      paging: true,
      filtering: true,
      endpoint: 'availableTransactionMerchantIds',
      resource: 'availableTransactionMerchantId',
      inputType: 'AvailableTransactionMerchantIdsFilter',
    },
    update: {
      endpoint: 'addExternalMerchantIDByTransactionID',
      resource: 'benefitApplication',
      inputType: 'AddExternalMerchantIDByTransactionIDInput!',
      fields: 'id externalMerchantIDs { merchantID acquireReferenceNumber }',
    },
  },
  incidentMessage: {
    fields: `id message messageType lastEditedTime hiddenTime lastEditedBy hiddenBy`,
    getList: {
      resource: 'availableIncidentCommunicationMessagesList',
      paging: false,
      filtering: false,
      endpoint: 'availableIncidentCommunicationMessagesList',
    },
    create: {
      endpoint: 'addIncidentCommunicationMessage',
      resource: `incidentMessage`,
      inputType: `IncidentMessageInput!`,
    },
    getOne: {
      endpoint: 'incidentMessageById',
      resource: 'incidentMessageById',
      fields: `id message messageType lastEditedTime hiddenTime lastEditedBy hiddenBy`,
    },
    delete: {
      fields: `success`,
      endpoint: 'hideIncidentCommunicationMessage',
    },
    update: {
      endpoint: `editIncidentCommunicationMessage`,
      inputType: `EditIncidentMessageInput!`,
      fields: `incidentMessage`,
    },
  },
  paymentBatch: {
    fields: `id status creator { id, fullName } closer { id, fullName } poster { id, fullName } createTime description closeTime summary { transactionCount, currencies { amount, currency } }`,
    getList: {
      paging: true,
      resource: 'paymentBatch',
      endpoint: 'paymentBatches',
      filtering: true,
    },
    getOne: {
      idType: 'Int',
    },
    delete: {
      idType: 'Int',
    },
    update: {
      fields: 'id description',
    },
    submit: {
      endpoint: 'postPaymentBatch',
    },
  },
  paymentTransaction: {
    fields: `id status zevoyType amount currency enfuceType enfuceTransactionId enfuceDate enfuceText organization { name } enfuceAccountId externalId comments resCode resBody`,
    getList: { paging: true, filtering: true },
    getOne: {
      idType: 'Int',
    },
    delete: {
      idType: 'Int',
    },
    update: {
      fields:
        'id zevoyType amount currency comments enfuceText enfuceDate enfuceTransactionId externalId ',
    },
    deleteMany: {
      idType: 'Int',
    },
  },
}

export const dataProvider: DataProvider = {
  getList: (resource, { sort, pagination, filter }) => {
    const getListResource = resources[resource].getList?.resource ?? resource
    const endpoint = resources[resource].getList?.endpoint ?? `${getListResource}s`
    const filtering = resources[resource].getList?.filtering ?? false
    const filterType =
      resources[resource].getList?.inputType ?? `${upperFirst(getListResource)}Filter `

    const { page, perPage: limit } = pagination
    const offset = (page - 1) * limit + 1
    const paging = resources[resource].getList?.paging
    if (paging) {
      return client
        .query({
          query: gql`
            query ${endpoint}($limit: Int, $offset: ID ${
            filtering ? ', $filter: ' + filterType : ''
          }) {
                ${endpoint} (input: {from: $offset, limit: $limit}${
            filtering ? ', filter: $filter' : ''
          }) {
                  totalCount
                  edges {
                    cursor
                    edge {
                      ${resources[resource].fields}
                    }
                  }
                  pageInfo {
                    startCursor
                    endCursor
                    nextCursor
                    size
                    hasNextPage
                  }
                }
            }`,
          variables: {
            limit: limit,
            offset: offset,
            filter: filter,
          },
          fetchPolicy: 'no-cache',
        })
        .then((result) => {
          return {
            data: result.data[endpoint].edges.map((item: { edge: any }) => item.edge),
            total: result.data[endpoint].totalCount,
          }
        })
    } else if (filtering) {
      return client
        .query({
          query: gql`
            query ${endpoint}($filter: ${filterType}) {
                ${endpoint} (filter: $filter) {
                ${resources[resource].fields}
            }
            }`,
          variables: {
            filter: filter,
          },
        })
        .then((result) => ({
          data: result.data[`${getListResource}s`],
          total: result.data[`${getListResource}s`].length,
        }))
    }

    return client
      .query({
        query: gql`
            query ${endpoint} {
                ${endpoint} {
                ${resources[resource].fields}
            }
            }`,
      })
      .then((result) => {
        return {
          data: result.data[`${getListResource}`] ?? [],
          total: result.data[`${getListResource}`]?.length,
        }
      })
  },
  getOne: (resource, params) => {
    const getOneResource = resources[resource].getOne?.resource ?? resource
    const endpoint = resources[resource].getOne?.endpoint ?? getOneResource
    const idParameter = resources[resource].getOne?.idParameter ?? 'id'
    const idType = resources[resource].getOne?.idType
    const id = idType === 'Int' ? parseInt(params.id) : `"${params.id}"`

    return client
      .query({
        query: gql`
            query get${upperFirst(resource)} {
                ${endpoint}(${idParameter}: ${id}) {
                    ${resources[resource].getOne?.fields ?? resources[resource].fields}
                }
            }`,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        return {
          data: result.data[getOneResource],
        }
      })
  },
  getMany: (_resource, _params) => {
    return Promise.reject('unsupported')
  },
  getManyReference: (_resource, _params) => {
    return Promise.reject('unsupported')
  },
  create: (resource, params) => {
    const endpoint = resources[resource].create?.endpoint ?? `create${upperFirst(resource)}`
    const createResource = resources[resource].create?.resource ?? resource
    const inputType = resources[resource].create?.inputType || `${upperFirst(resource)}Input!`

    return client
      .mutate({
        mutation: gql`
          mutation create${upperFirst(resource)}($input: ${inputType}) {
            ${endpoint}(input: $input) {
              success
              ${createResource} { id }
            }
          }`,
        variables: {
          input: omit(params.data, ['__typename']),
        },
      })
      .then((result) => {
        return {
          data: result.data[endpoint][createResource],
        }
      })
  },
  update: (resource, params) => {
    const endpoint = resources[resource].update?.endpoint ?? `update${upperFirst(resource)}`
    const updateResource = resources[resource].update?.resource ?? resource
    const inputTypeName =
      resources[resource].update?.inputType ?? `Update${upperFirst(resource)}Input!`
    const updateFields = resources[resource].update?.fields

    const input = !!updateFields
      ? pick(params.data, updateFields.split(' '))
      : omit(params.data, ['__typename'])

    return client
      .mutate({
        mutation: gql`
            mutation update${upperFirst(resource)} ($input: ${inputTypeName}) {
              ${endpoint}(input: $input) {
                success
                ${updateResource} { ${resources[updateResource].fields} }
              }
            }`,
        variables: {
          input,
        },
      })
      .then((result) => ({
        data: result.data[endpoint][updateResource],
      }))
  },
  updateMany: (_resource, _params) => {
    return Promise.reject('unsupported')
  },
  delete: (resource, params) => {
    const endpoint = resources[resource].delete?.endpoint ?? `delete${upperFirst(resource)}`
    const deleteResource = resources[resource].delete?.resource ?? resource
    const idType = resources[resource].delete?.idType
    const idParameter = resources[resource].delete?.idParameter ?? 'id'
    const id = idType === 'Int' ? params.id : `"${params.id}"`

    return client
      .mutate({
        mutation: gql`
            mutation delete${upperFirst(resource)}{
                ${endpoint}(${idParameter}: ${id}) {
                  success
                }
            }`,
      })
      .then((result) => ({
        data: result.data[endpoint][deleteResource],
      }))
  },
  deleteMany: (resource, params) => {
    const endpoint = resources[resource].deleteMany?.endpoint ?? `delete${upperFirst(resource)}s`
    const idType = resources[resource].deleteMany?.idType
    const idParameter = resources[resource].deleteMany?.idParameter ?? 'ids'
    const ids = idType === 'Int' ? `[${params.ids}]` : `["${params.ids.join('","')}"]`

    return client
      .mutate({
        mutation: gql`
            mutation delete${upperFirst(resource)}s{
                ${endpoint}(${idParameter}: ${ids}) {
                  success
                }
            }`,
      })
      .then(() => ({
        data: [],
      }))
  },
  submit: (resource: string, id: string) => {
    const endpoint = resources[resource].submit?.endpoint ?? `submit${upperFirst(resource)}`
    const submitResource = resources[resource].submit?.resource ?? resource

    return client
      .mutate({
        mutation: gql`
            mutation submit${upperFirst(resource)} ($id: CardRequestID!) {
              ${endpoint}(id: $id) {
                success
              }
            }`,
        variables: {
          id: id,
        },
      })
      .then((result) => ({
        data: result.data[endpoint][submitResource],
      }))
  },
  approve: (resource: string, params: UpdateParams) => {
    const endpoint = resources[resource].approve?.endpoint ?? `approve${upperFirst(resource)}`
    const approveResource = resources[resource].approve?.resource ?? resource
    const inputTypeName =
      resources[resource].approve?.inputType ?? `Approve${upperFirst(resource)}Input!`

    return client
      .mutate({
        mutation: gql`
          mutation approve${upperFirst(resource)} ($input: ${inputTypeName}) {
              ${endpoint}(input: $input) {
                 ${approveResource} { ${resources[approveResource].fields} }
            }
          }`,
        variables: {
          input: omit(params.data, ['__typename']),
        },
      })
      .then((result) => ({
        data: result.data[endpoint][approveResource],
      }))
  },
  reject: (resource: string, params: UpdateParams) => {
    const endpoint = resources[resource].reject?.endpoint ?? `approve${upperFirst(resource)}`
    const rejectResource = resources[resource].reject?.resource ?? resource
    const inputTypeName =
      resources[resource].reject?.inputType ?? `Reject${upperFirst(resource)}Input!`

    return client
      .mutate({
        mutation: gql`
          mutation reject${upperFirst(resource)} ($input: ${inputTypeName}) {
              ${endpoint}(input: $input) {
              ${rejectResource} { ${resources[rejectResource].fields} }
          }
          }`,
        variables: {
          input: omit(params.data, ['__typename']),
        },
      })
      .then((result) => ({
        data: result.data[endpoint][rejectResource],
      }))
  },
}
