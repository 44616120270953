import { DateTime } from 'luxon'
import { ComponentVariants } from '../constants'

export const sortCollectionByPropertyInAlphabeticalOrder = <T extends object>(
  arr: T[],
  property: string,
): T[] => {
  if (!arr?.length) {
    return []
  }

  if (arr.length === 1) {
    return arr
  }

  return [...arr].sort((currentObject: T, nextObject: T) => {
    const currentString: any = currentObject?.[property as keyof T] ?? ''
    if (!currentString || typeof currentString !== 'string') {
      return 1
    }

    const nextString: any = nextObject?.[property as keyof T] ?? ''
    if (!nextString || typeof nextString !== 'string') {
      return -1
    }
    return currentString.toLowerCase().localeCompare(nextString.toLowerCase())
  })
}

export const getMonth = (dateTime: string): string => DateTime.fromISO(dateTime).monthLong

export const getYear = (dateTime: string): number => DateTime.fromISO(dateTime).year

export const removeLastDirectoryFromUrl = (url: string) => {
  return url.replace(/\/[^/]*$/, '')
}

export const mapEnumToArray = <P extends object>(enumObject: P) => {
  return Object.keys(enumObject).map((key) => enumObject[key as keyof P])
}

export const convertToPx = (value: string) => {
  if (value.includes('px')) {
    return parseInt(value.replace('px', ''))
  }

  if (value.includes('rem')) {
    const remValue = parseFloat(value.replace('rem', ''))
    return remValue * 16
  }

  if (value.includes('em')) {
    const emValue = parseFloat(value.replace('em', ''))
    return emValue * 16
  }

  return 0
}

export const getDisabledVariant = (variant: ComponentVariants | undefined) => {
  switch (variant) {
    case ComponentVariants.Primary:
      return ComponentVariants.DisabledPrimary
    case ComponentVariants.Secondary:
    case ComponentVariants.NegativeSecondary:
      return ComponentVariants.DisabledSecondary
    default:
      return variant
  }
}

export const getUrlPathParts = () => {
  const pathname = window.location.pathname
  return pathname.split('/').filter((part) => part !== '')
}

export const getUrlPathChunk = (index: number): string => {
  const parts = getUrlPathParts()
  return parts?.[index] ?? ''
}

export const getUrlPath = (depth: number): string => {
  const pathLevels = getUrlPathParts().slice(0, depth)
  return `${pathLevels.reduce((acc, cur) => `${acc}/${cur}`, '')}`
}

// checks if string is a correctly formatted numerical amount OR a partial float
// (e.g. 123. or 123.4) - needed for numerical input validation. More use cases - in tests
//
// Update: added unlimitedDigitsAfterDot parameter to allow unlimited digits after dot
export const isNumericalAmountForInput = (value: string, unlimitedDigitsAfterDot?: boolean) => {
  if (!value) {
    return false
  }
  const regexUnlimited = /^-?$|^-?0([.,]\d*)?$|^-?[1-9]\d*([.,]\d*)?$/
  const regexLimited = /^-?$|^-?0([.,]\d{0,2})?$|^-?[1-9]\d*([.,]\d{0,2})?$/

  return unlimitedDigitsAfterDot ? regexUnlimited.test(value) : regexLimited.test(value)
}
