import tw, { css, styled, theme } from 'twin.macro'
import { FlattenSimpleInterpolation } from 'styled-components'

type InputWrapperProps = {
  isError?: boolean
  isDisabled?: boolean
  isOpen?: boolean
  width?: string
  height?: string
  styles?: FlattenSimpleInterpolation
}
export const InputWrapper = styled.div<InputWrapperProps>`
  ${tw`flex flex-row h-[60px] border-1 border-zevoyGray3 text-sm cursor-pointer border-solid px-[20px] py-[10px] box-border items-center rounded-md bg-white`}
  ${css`
    &:hover svg:not(.clear-field) {
      ${css`
        fill: ${theme('colors.zevoyGray2')};
      `}
    }
  `}
  ${({ styles }) => styles}
  ${({ isDisabled }) =>
    isDisabled ? tw`cursor-default bg-zevoyGray4` : tw`hover:border-zevoyGray2`}
  ${({ isError, isDisabled, isOpen }) =>
    isError && !isDisabled && tw`border-zevoyNegative hover:border-zevoyNegative`}
  ${({ width }) => width && `width: ${width}`}
  ${({ height }) => height && `height: ${height}`}
`
