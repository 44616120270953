import { useEffect, useMemo, useState } from 'react'
import { DetailsHeader, DetailsUnderline, FlexRow, ModalInputLabel } from '../styles'
import tw from 'twin.macro'
import { SelectComponent } from '@zevoy/common/src/components/SelectComponent'
import { Option } from '@zevoy/common/src/types/Generic'
import { InfoWrapper } from '../../Prospects/styles'
import {
  AccountStatus,
  AccountStatusInput,
  Organization,
  OrganizationStatus,
  useDisableInterestPostingMutation,
  useEnableInterestPostingMutation,
  useUpdateOrganizationAccountStatusMutation,
} from '../../../GeneratedGraphQLTypes'
import { SaveButton } from '../../../lib/styles'
import { toast } from 'react-toastify'
import Slider from '../../../lib/components/Slider'

const Wrapper = tw(InfoWrapper)`p-0 pt-8`

const statusOptions: Option[] = [
  {
    label: 'ACCOUNT_OK',
    value: AccountStatusInput.AccountOk,
  },
  {
    label: 'ACCOUNT_BLOCKED',
    value: AccountStatusInput.AccountBlocked,
  },
  {
    label: 'ACCOUNT_TO_CLOSE',
    value: AccountStatusInput.AccountToClose,
  },
]

const mapAccountStatusToInput = (status: AccountStatus): string => {
  switch (status) {
    case AccountStatus.Ok:
      return AccountStatusInput.AccountOk
    case AccountStatus.Blocked:
      return AccountStatusInput.AccountBlocked
    case AccountStatus.ToClose:
      return AccountStatusInput.AccountToClose
  }
  return ''
}

type Props = {
  organization: Organization
}

export const EnfuceAccounts = ({ organization }: Props) => {
  const [updateOrgAccountStatus] = useUpdateOrganizationAccountStatusMutation()
  const [enableInterestPosting] = useEnableInterestPostingMutation()
  const [disableInterestPosting] = useDisableInterestPostingMutation()

  const options = useMemo(
    () =>
      organization.allAccounts
        .filter((account) => !account.parent)
        .map((account) => {
          return {
            label: `${account.id}(${account.status})`,
            value: account.id,
          }
        }),
    [organization],
  )

  const [accountId, setAccountId] = useState<string>('')
  const [status, setStatus] = useState<string>('')
  const [interestPosting, setInterestPosting] = useState<boolean>(false)

  const currentStatus = useMemo(() => {
    const account = organization.allAccounts.find((account) => account.id === accountId)
    return account ? mapAccountStatusToInput(account.status) : ''
  }, [accountId, organization.allAccounts])

  const currentInterestPosting = useMemo(() => {
    const account = organization.allAccounts.find((account) => account.id === accountId)
    return account?.interestPostingEnabled ?? true
  }, [accountId, organization.allAccounts])

  useEffect(() => {
    if (currentStatus) {
      setStatus(currentStatus)
    }
  }, [currentStatus])

  useEffect(() => {
    setInterestPosting(currentInterestPosting)
  }, [currentInterestPosting])

  const handleSaveStatus = async () => {
    try {
      await updateOrgAccountStatus({
        variables: {
          input: {
            accountID: accountId,
            status: status as AccountStatusInput,
          },
        },
      })
      toast.success('Organization account status saved!')
    } catch (e) {
      const err: Error = e as Error
      console.error(err?.message ?? e)
      toast.error('Failed to save!')
    }
  }

  const handleSaveInterestPosting = async () => {
    try {
      if (interestPosting) {
        await enableInterestPosting({
          variables: {
            accountID: accountId,
          },
        })
      } else {
        await disableInterestPosting({
          variables: {
            accountID: accountId,
          },
        })
      }
      toast.success('Account interest posting saved!')
    } catch (e) {
      const err: Error = e as Error
      console.error(err?.message ?? e)
      toast.error('Failed to save!')
    }
  }

  return (
    <Wrapper>
      <DetailsHeader>Enfuce Accounts</DetailsHeader>
      <DetailsUnderline />
      <FlexRow>
        <div>
          <ModalInputLabel>Account:</ModalInputLabel>
          <SelectComponent
            options={options}
            width="200px"
            value={options.find((option: Option) => option.value === accountId)}
            onChange={(newValue) => {
              setAccountId(newValue?.value as OrganizationStatus)
            }}
          />
        </div>
        <div>
          <ModalInputLabel>Status:</ModalInputLabel>
          <SelectComponent
            options={statusOptions}
            width="200px"
            isDisabled={currentStatus === ''}
            value={statusOptions.find((option: Option) => option.value === status)}
            onChange={(newValue) => {
              setStatus(newValue?.value as string)
            }}
          />
        </div>
      </FlexRow>
      <SaveButton disabled={status === '' || status === currentStatus} onClick={handleSaveStatus}>
        Save Status
      </SaveButton>
      <DetailsUnderline />
      <FlexRow>
        <div>
          <ModalInputLabel>Interest Posting:</ModalInputLabel>
          <Slider selected={interestPosting} onClick={() => setInterestPosting(!interestPosting)} />
        </div>
      </FlexRow>

      <SaveButton
        disabled={interestPosting === currentInterestPosting || accountId === ''}
        onClick={handleSaveInterestPosting}
      >
        Save
      </SaveButton>
    </Wrapper>
  )
}
