import React, { useEffect, useRef, useState } from 'react'
import Select, {
  GroupBase,
  Props,
  ControlProps,
  components,
  SelectInstance,
  OptionProps,
  MultiValue,
} from 'react-select'
import CreatableSelect, { CreatableProps } from 'react-select/creatable'
import tw, { css, theme } from 'twin.macro'
import { ErrorMessage } from '../ErrorMessage'
import { CSSProp } from 'styled-components'
import { Label } from '../Label'
import { Inline, Stack } from '../Spacing'
import { ReactComponent as Checked } from '@zevoy/common/assets/icons/checked.svg'
import {
  Chevron,
  SelectPlaceholder,
  SelectedRow,
  SelectedRowWrapper,
  Wrapper,
  getSelectCustomStyles,
  ClearSelection,
} from './styled'
import { InputWrapper } from '../TextInput/styled'
import { ReactComponent as Delete } from '@zevoy/common/assets/icons/bin_icon.svg'
import { Option } from '../../types/Generic'
import { InfoBox } from '../InfoBox'
import { ColorVariants } from '../../constants'
import { IconButton } from '../Icon/styled'

export const ControlInner = tw.div`flex-1`
export const CheckedIcon = tw(Checked)`flex-initial fill-zevoyGray3 mr-[5px] w-[13px] h-[10px]`

type SelectPropsWithCustomProps<
  OptionType extends Option,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
> = Partial<Props<OptionType, IsMulti, GroupType>> &
  Partial<SelectComponentPropsNew<OptionType, IsMulti, GroupType>>

const ControlComponent = <
  OptionType extends Option,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  controlProps: ControlProps<OptionType, IsMulti, GroupType> & {
    selectProps: SelectPropsWithCustomProps<OptionType, IsMulti, GroupType>
  },
) => {
  return (
    <InputWrapper
      isError={!!controlProps.selectProps.errorMessage}
      isDisabled={controlProps.isDisabled}
      isOpen={controlProps.menuIsOpen}
      width={controlProps.selectProps.width}
    >
      <Inline
        styled={css`
          flex: 1;
        `}
      >
        <ControlInner>
          <Label insideInput>{controlProps.selectProps.label}</Label>
          <components.Control {...controlProps} />
          {!controlProps.menuIsOpen && controlProps.isMulti && (
            <SelectPlaceholder>{controlProps.selectProps.placeholder}</SelectPlaceholder>
          )}
        </ControlInner>
        {controlProps.selectProps.isClearable && controlProps.hasValue && (
          <ClearSelection
            onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
              e.stopPropagation()
              controlProps.clearValue()
            }}
          />
        )}

        <Chevron />
      </Inline>
    </InputWrapper>
  )
}

const OptionComponent = <
  OptionType extends Option,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  optionProps: OptionProps<OptionType, IsMulti, GroupType>,
) => {
  return (
    <components.Option {...optionProps}>
      <Inline>
        <ControlInner>{optionProps.label}</ControlInner>
        {optionProps.isSelected && <CheckedIcon />}
      </Inline>
    </components.Option>
  )
}

// Show empty for multiple select in control bar as the universal design.
const MultiValueComponent = () => {
  return <></>
}

export interface SelectComponentPropsNew<
  OptionType extends Option,
  IsMulti extends boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
> extends Props<OptionType, IsMulti, GroupType>,
    CreatableProps<OptionType, IsMulti, GroupType> {
  errorMessage?: string
  width?: string
  label?: string
  labelCss?: CSSProp<any>
  creatable?: boolean
  onDeleteOption?: (option: OptionType) => void
  infoMessage?: string | React.ReactNode
}

export const SelectComponentNew = <
  OptionType extends Option,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
  props: SelectComponentPropsNew<OptionType, IsMulti, GroupType>,
) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const selectRef = useRef<SelectInstance<OptionType, IsMulti, GroupType>>(null)
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)

  const toggleMenuIsOpen = () => {
    setMenuIsOpen((value) => !value)
    const selectEl = selectRef.current
    if (!selectEl) return
    if (menuIsOpen) selectEl.blur()
    else selectEl.focus()
  }

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (!wrapperRef.current?.contains(e.target)) {
        setMenuIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const wrapperCalculatedWidth = `${wrapperRef.current?.getBoundingClientRect().width}px` || 'auto'
  const selectProps: SelectPropsWithCustomProps<OptionType, IsMulti, GroupType> = {
    components: {
      Control: ControlComponent,
      Option: OptionComponent,
      MultiValue: MultiValueComponent,
      ...props.components,
    },
    menuIsOpen: props.isDisabled ? false : menuIsOpen,
    placeholder: props.placeholder,
    styles: {
      ...getSelectCustomStyles(wrapperCalculatedWidth, props.menuPortalTarget, props.isDisabled),
      ...props.styles,
    },

    // custom props
    errorMessage: props.errorMessage,
    width: props.width,
  }

  if (props.isMulti) {
    const multiValue = props.value as MultiValue<OptionType>
    const multiValues = multiValue ? Array.from(multiValue) : []
    return (
      <Stack gap={1}>
        <Wrapper onClick={toggleMenuIsOpen} ref={wrapperRef} width={props.width}>
          {props.creatable ? (
            <CreatableSelect {...props} {...selectProps} />
          ) : (
            <Select {...props} {...selectProps} ref={selectRef} />
          )}
        </Wrapper>
        {multiValues.length > 0 && (
          <SelectedRowWrapper>
            {multiValues.map((optionValue) => (
              <SelectedRow key={optionValue.value}>
                <div>{optionValue.label}</div>
                <IconButton
                  onClick={() => {
                    if (props.onDeleteOption) {
                      props.onDeleteOption(optionValue)
                    }
                  }}
                  style={{ background: theme('colors.zevoyNegativeLight') }}
                >
                  <Delete />
                </IconButton>
              </SelectedRow>
            ))}
          </SelectedRowWrapper>
        )}

        {props.errorMessage && <ErrorMessage error={props.errorMessage} isFieldError />}
      </Stack>
    )
  }

  return (
    <Wrapper ref={wrapperRef} width={props.width}>
      <Stack>
        <div onClick={toggleMenuIsOpen}>
          {props.creatable ? (
            <CreatableSelect {...props} {...selectProps} />
          ) : (
            <Select {...props} {...selectProps} ref={selectRef} />
          )}
        </div>
        {props.infoMessage && <InfoBox message={props.infoMessage} type={ColorVariants.Info} />}
        {props.errorMessage && <ErrorMessage error={props.errorMessage} isFieldError />}
      </Stack>
    </Wrapper>
  )
}
